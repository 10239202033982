import React, { useEffect, useState, useRef, Alert } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import "./style.css";
import { Row, Col, Card, Button } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import kmBanner from "././assets/gallery/kartik-mahotsav.jpg";
import krishnachanting from "./assets/gallery/janamshtami/chanting.mp3";
import audioOn from "./assets/gallery/janamshtami/audio-on.png";
import audioOff from "././assets/gallery/janamshtami/audio-off.png";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import axios from "./interceptor";
import urls from "./endpoint";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
const GitaDaan = () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const options = [
    { value: null, label: "Please Select Gita Daan" },
   
    { value: "4001", label: "Gita Daan -16 Books" },
    { value: "8001", label: "Gita Daan -32 Books" },
    { value: "13551", label: "Gita Daan -54 Books" },
    { value: "27001", label: "Gita Daan -108 Books" },
    { value: "3201", label: "Pocket Gita -16 Books" },
    { value: "6401", label: "Pocket Gita -32 Books" },
    { value: "10801", label: "Pocket Gita -54 Books" },
    { value: "21601", label: "Pocket Gita -108 Books" },
    { value: "", label: "Any Other Amount" },
  ];
  const [selectedOptionForDonation, setSelectedOptionForDonation] = useState(
    options[0]
  );
  const [donorData, setDonorData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    address: "",
    dob: null,
    amount: "",
  });
  const [bookImage, setBookImage] = useState({
    image:
      "http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png",
    height: "450px",
    width: "100%",
  });
  const [devoteeData, setDevoteeData] = useState({});
  const audioRef = useRef(new Audio(krishnachanting));
  const { vid } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [errors, setErrors] = useState({});
  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);
  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);
  const handleBookImage = (image, height, width) => {
    setBookImage(() => ({ image: image, height: height, width: width }));
  };
  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
      audio.play();
    };

    audio.addEventListener("ended", handleEnded, false);

    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const playPause = () => {
    const audio = audioRef.current;
    const wasPlaying = isAudioPlaying;

    setIsAudioPlaying(!wasPlaying);

    if (wasPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  };
  const handleChangeSelect = (option) => {
    console.log(option, "option from handleselect");
    setSelectedOptionForDonation(option);
  };
  const handledonorData = (e) => {
    const { name, value } = e.target;
    console.log(e.target.value);
    setDonorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDonateNow = () => {
    const newErrors = {};
    let hasError = false;

    if (!donorData.name) {
      newErrors.name = "Donor Name is required";
      hasError = true;
    }

    if (!donorData.email) {
      newErrors.email = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.email)) {
      newErrors.email = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobile_number) {
      newErrors.mobile_number = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (!selectedOptionForDonation.value && donorData.amount === '') {
      newErrors.gitadaan = "Please select atleast one Gita Daan";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    console.log("hi from donate now");
    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        console.log(res, "from 196");
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();

        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedOptionForDonation.value) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedOptionForDonation.label,
              image: { iskconabidslogo },
              order_id: res.data.data.order_id,
              handler: function (response) {
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.name, //your customer's name
                email: donorData.email,
                contact: donorData.mobile_number, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.name,
      devotee_mobile_no: donorData.mobile_number,
      devotee_email_id: donorData.email,
      devotee_dob: donorData.dob,
      devotee_address: donorData.address,
      
      sevas: [
        {
          name: selectedOptionForDonation.label,
          amount: selectedOptionForDonation.label === "Any Other Amount" 
            ? donorData.amount 
            : selectedOptionForDonation.value,
        },
      ],

      vid: vid,
      festival_name: selectedOptionForDonation.label,
    }));
  }, [donorData, selectedOptionForDonation]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    try {
      console.log(devoteeData, "data");
      const response = await axios.post(urls.create_donation, devoteeData);
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      alert("Error", "Failed to create donation. Please try again.");
      throw error;
    }
    // const response = await axios.post(urls.create_donation, devoteeData);

    // return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    if (paymentStatus === "paid") {
      handleShow();
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          handleClose();
          handleShowSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div>
      <div
        className="video-background"
        style={{ backgroundColor: "black" }}
      ></div>
      <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-9 col-5 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-3 col-7  text-left ">
              <button
                className="btn btn-h-donate "
                onClick={playPause}
                style={{ borderRadius: "30px" }}
              >
                {isAudioPlaying ? (
                  <>
                    <marquee width="60%" direction="right" height="18px">
                      Hare Krishna Mantra
                    </marquee>
                    <img
                      src={audioOn}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Pause"
                    />
                  </>
                ) : (
                  <>
                    Hare Krishna Mantra
                    <img
                      src={audioOff}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Play"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className="banner-image">
                <img
                  src={
                    "https://iskconhyderabad.com/wp-content/uploads/2020/11/book.jpeg"
                  }
                  alt="Banner"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          background: "#302859",
        }}
      >
        <div style={{ margin: "5%", width: "100%", height: "100%" }}>
          <h2 className="gyaanDaan"> Gyaan Daan is a Maha Daan</h2>

          <h4 className="benefitsText">
            The benefits are immense both for the donor and the recipient. It
            spreads the knowledge of the absolute truth and affording a person
            the chance to perfect their life, and there is no flip side to
            donating the Gita.
          </h4>
          <h4 className="benefitsText">
            Gita is considered by the university as an excellent source of
            spiritual knowledge and thought for humanity.
          </h4>
          <h4 className="benefitsText">
            The Gita teaches us the right way to lead life, spread the knowledge
            by donating copies of BHAGAVAD Gita, and thus help people get the
            spiritual solution to their material problems.
          </h4>
          <h4 className="benefitsTextCenter mt-5">
            ~ Lets donate Bhagavatam and spread the Gyaan ~
          </h4>
          <h4 className="gyaanDaan mt-5">
            Gita helps to reduce sinful habits​
          </h4>
          <h4 className="benefitsText">
            There is also a noisy increase in social crimes and additions to
            sinful habits; Bhagavad Gita is the best solution to educate people.
          </h4>
          <h4 className="benefitsText">
            The timeless wisdom of Gita, present with parampara explanation by
            Srila Prabhupada, has transformed into millions of people’s lives
            all over the world five decades from now.
          </h4>
          <h4 className="benefitsText">
            A plate of food can mitigate the hunger for one time, whereas
            knowledge can transform a person’s life.
          </h4>
        </div>
      </section>

      <div
        style={{
          height: "93%",
          width: "93%",

          margin: "5%",
        }}
      >
        <Row>
          <Col sm={12} md={3}>
            <div style={{ flexDirection: "column" }}>
              <div>
                <img
                  src={bookImage.image}
                  style={{ height: bookImage.height, width: bookImage.width }}

                  // className="bookImage"
                />
              </div>

              <Row style={{ marginTop: "5%" }}>
                <Col>
                  <div
                    onClick={() => {
                      handleBookImage(
                        "http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png",
                        "450px",
                        "100%"
                      );
                    }}
                  >
                    <img
                      src="http://iskconhyderabad.com/wp-content/uploads/2020/11/bg_asitis_trans-1-600x887-1.png"
                      style={{
                        height: "5%",
                        width: "60%",
                        marginLeft: "20%",
                        border: "2px solid white",
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div
                    onClick={() => {
                      handleBookImage(
                        "https://iskconhyderabad.com/wp-content/uploads/IMG-20211229-WA0004.jpg",
                        "450px",
                        "105%"
                      );
                    }}
                  >
                    <img
                      src="https://iskconhyderabad.com/wp-content/uploads/IMG-20211229-WA0004.jpg"
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "4%",
                        border: "2px solid white",
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div
                    onClick={() => {
                      handleBookImage(
                        "https://iskconhyderabad.com/wp-content/uploads/IMG-20220114-WA0041.jpg",
                        "450px",
                        "105%"
                      );
                    }}
                  >
                    <img
                      src="https://iskconhyderabad.com/wp-content/uploads/IMG-20220114-WA0041.jpg"
                      style={{
                        height: "100%",
                        width: "100%",
                        marginTop: "4%",
                        border: "2px solid white",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col>
            <Card
              style={{
                height: "93%",
                width: "100%",
                border: "3px solid white ",
                marginTop: "1%",
                marginLeft: "1%",
                background: "#FEFCFF",
              }}
            >
              <Form style={{ margin: "3%" }}>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Donation For</Form.Label>
                      <Select
                        value={selectedOptionForDonation}
                        onChange={handleChangeSelect}
                        options={options}
                      />
                      {errors.gitadaan && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.gitadaan}
                        </p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "8px",
                          width: "100%",
                        }}
                        name="name"
                        value={donorData.name}
                        onChange={handledonorData}
                      />
                      <div>
                        {errors.name && (
                          <p className="error " style={{ color: "red" }}>
                            {errors.name}
                          </p>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={donorData.email}
                        onChange={handledonorData}
                      />
                      {errors.email && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.email}
                        </p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date Of Birth (optional)</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="DD/MM/YYYY"
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "8px",
                        }}
                        name="dob"
                        value={donorData.dob}
                        onChange={handledonorData}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        placeholder="Enter amount"
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                        name="amount"
                        value={selectedOptionForDonation.label !== "Any Other Amount" ?selectedOptionForDonation.value :donorData.amount}
                        onChange={handledonorData}
                        disabled={
                          selectedOptionForDonation?.label !== "Any Other Amount"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter mobile number"
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                          padding: "8px",
                          width: "100%",
                        }}
                        maxLength={10}
                        name="mobile_number"
                        value={donorData.mobile_number}
                        onChange={handledonorData}
                      />
                      {errors.mobile_number && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.mobile_number}
                        </p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textArea"
                        rows={2}
                        placeholder="Enter Address"
                        name="address"
                        value={donorData.address}
                        onChange={handledonorData}
                      />
                      {errors.address && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.address}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <p className="pg-note desk text-center payment-message mt-3 tax-note">
                  By continuing, you are agreeing to
                  <a
                    href="https://iskconhyderabad.com/terms-and-conditions/"
                    target="_blank"
                    style={{ marginLeft: "1%" }}
                  >
                    Terms of Use and Privacy Policy
                  </a>
                </p>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "3%",
                    marginBottom: "3%",
                  }}
                >
                  <Button
                    variant="warning"
                    style={{ height: "50px" }}
                    onClick={handleDonateNow}
                  >
                    DONATE NOW ₹ {selectedOptionForDonation.label !== "Any Other Amount" ?selectedOptionForDonation.value :donorData.amount}
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
        <h2 className="gyaanDaan mt-5">OR</h2>
        <div>
          <Card
            style={{
              height: "93%",
              width: "100%",
              border: "3px solid white ",
              marginRight: "1%",
              marginLeft: "1%",
              background: "#FEFCFF",
            }}
          >
            <Row>
              <Col md={6} sm={12}>
                <h5 className="scanText">SCAN & PAY WITH ANY UPI APP</h5>
                <img
                  src={
                    "http://iskconhyderabad.com/wp-content/uploads/ISKCON-HYD-QR.png"
                  }
                  style={{
                    height: "70%",
                    width: "45%",
                    marginLeft: "30%",
                    marginTop: "5%",
                  }}
                />
              </Col>
              <Col md={6} sm={12}>
                <h5 className="scanText"> DIRECT BANK NEFT/RTGS/IMPS</h5>
                <div style={{ marginTop: "15%" }}>
                  <h3 className="bankDetailsText">ISKCON Account Details:</h3>
                  <h3 className="bankDetailsText">
                    Name of account: International Society for Krishna
                    Consciousness
                  </h3>
                  <h3 className="bankDetailsText">A/C # 041001005498</h3>
                  <h3 className="bankDetailsText">IFSC Code: ICIC0000410</h3>
                  <h3 className="bankDetailsText">ICICI Bank Abids Branch.</h3>
                </div>
              </Col>
            </Row>
            <h4 className="receiptText mb-5">
              For Receipts and other Details: +917559441515 Email:
              iyshyderabad@gmail.com
            </h4>
          </Card>
          <style jsx>{`
            .loading-spinner-container {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              z-index: 9999;
            }
          `}</style>
        </div>
        <Modal
          show={modalShow}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Processing Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-spinner-container">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={true}
                style={{ alignItems: "center", justifyContent: "center" }}
              />
            </div>

            <p style={{ marginTop: "25%", color: "blue" }}>
              Please Wait. Your payment is currently being processed.
              <br /> Do not refresh or close your browser.
            </p>
          </Modal.Body>
        </Modal>

        <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment Sucessful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p style={{ marginTop: "5%", color: "green", fontSize: "18px" }}>
                Thanks For The Payment
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
export default GitaDaan;
