import React, { useEffect, useState, useRef, Alert } from "react";
import headerlogo from "./assets/img/iskcon-abids-logo.png";
import axios from "./interceptor";
import urls from "./endpoint";
import "./style.css";
import { Button } from "react-bootstrap";
import iskconabidslogo from "./assets/img/iskcon-abids-logo.png";
import leftdesign from "./assets/img/left-design.png";
import rightdesign from "./assets/img/right-design.png";

import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import kmBanner from "././assets/gallery/kartik-mahotsav.jpg";
import krishnachanting from "./assets/gallery/janamshtami/chanting.mp3";
import audioOn from "./assets/gallery/janamshtami/audio-on.png";
import audioOff from "././assets/gallery/janamshtami/audio-off.png";
import { RotatingLines } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
const KartikMahotsav = () => {
  const [devoteeData, setDevoteeData] = useState({});
  const [donorData, setDonorData] = useState({
    donorName: "",
    mobileNumber: "",
    emailId: "",
    dob: "",
    address: "",
   
  });
  const [modalShow, setModalShow] = useState(false);

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);
  const [modalShowSuccess, setModalShowSuccess] = useState(false);

  const handleShowSuccess = () => setModalShowSuccess(true);
  const handleCloseSuccess = () => setModalShowSuccess(false);

  const [sevasData, setSevasData] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedSevaLabel, setSelectedSevaLabel] = useState("");
  const [errors, setErrors] = useState({});
  const [anyCheckboxSelected, setAnyCheckboxSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isCheckedSevas, setIsCheckedSevas] = useState({});
  const [buttonClickedSevas, setButtonClickedSevas] = useState({});
  const [amountSevas, setAmountSevas] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const { vid } = useParams();
  const [otherAmounts, setOtherAmounts] = useState({});
  const [loading, setLoading] = useState(false);
  // const scrollToForm = () => {
  //   formRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [playInLoop, setPlayInLoop] = useState(true);

  const audioRef = useRef(new Audio(krishnachanting));

  useEffect(() => {
    const audio = audioRef.current;

    const handleEnded = () => {
      audio.currentTime = 0;
      audio.play();
    };

    audio.addEventListener("ended", handleEnded, false);

    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, []);

  const playPause = () => {
    const audio = audioRef.current;
    const wasPlaying = isAudioPlaying;

    setIsAudioPlaying(!wasPlaying);

    if (wasPlaying) {
      audio.pause();
    } else {
      audio.play().catch((error) => {
        console.error("Audio playback failed:", error);
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDonate = () => {
    const newErrors = {};
    let hasError = false;

    setShowError(true);
    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
   
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log(sevasData, "sevasData from ");
    setDevoteeData((prevData) => ({
      ...prevData,
      devotee_name: donorData.donorName,
      devotee_mobile_no: donorData.mobileNumber,
      devotee_email_id: donorData.emailId,
      devotee_dob: donorData.dob,
      devotee_address: donorData.address,
      sevas: sevasData,
    
      vid: vid,
      festival_name: "Kartik Mahotsav",
    }));
  }, [donorData, sevasData]);

  const CreateDonation = async () => {
    console.log(devoteeData, "data");
    try {
      console.log(devoteeData, "data");
      const response = await axios.post(urls.create_donation, devoteeData);
      return response;
    } catch (error) {
      console.error("API call failed:", error);
      alert("Error", "Failed to create donation. Please try again.");
      throw error;
    }
    // const response = await axios.post(urls.create_donation, devoteeData);

    // return response;
  };
  const updateDonation = (donationNum, paymentStatus, paymentID) => {
    if (paymentStatus === "paid") {
      handleShow();
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          handleClose();
          handleShowSuccess();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(urls.update_donation, {
          donation_no: donationNum,
          payment_status: paymentStatus,
          payment_id: paymentID,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDonateNow = () => {
    const selectedSevas = Object.keys(isCheckedSevas).filter(
      (seva) => isCheckedSevas[seva]
    );

    setSelectedSevaLabel(selectedSevas.join(" & "));
    const newErrors = {};
    let hasError = false;

    if (!donorData.donorName) {
      newErrors.donorName = "Donor Name is required";
      hasError = true;
    }
   
   
    if (!donorData.emailId) {
      newErrors.emailId = "Email Id is required";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(donorData.emailId)) {
      newErrors.emailId = "Please enter a valid email address";
      hasError = true;
    }

    if (!donorData.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
      hasError = true;
    }
    if (!donorData.dob) {
      newErrors.dob = "Date of Birth is required";
      hasError = true;
    }
    if (!donorData.address) {
      newErrors.address = "Address is required";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    console.log("hi from donate now");
    axios
      .post(urls.get_token, {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: "*",
      })
      .then((res) => {
        console.log(res, "from 196");
        Cookies.set("Token", res.data.access_token);
        const donationResponse = CreateDonation();

        donationResponse.then((res) => {
          console.log(res);
          if (res && res.status && res.status === 200) {
            var options = {
              key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
              amount: parseInt(selectedAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "ISKCON ABIDS", //your business name
              description: "Payment For " + selectedSevaLabel,
              image: { iskconabidslogo },
              order_id: res.data.data.order_id,
              handler: function (response) {
                updateDonation(
                  res.data.data.donation_no,
                  "paid",
                  response.razorpay_payment_id
                );
              },
              prefill: {
                //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                name: donorData.donorName, //your customer's name
                email: donorData.emailId,
                contact: donorData.mobileNumber, //Provide the customer's phone number for better conversion rates
              },

              theme: {
                color: "#3399cc",
              },
            };

            const rzp1 = new window.Razorpay(options);

            rzp1.on("payment.failed", function (response) {
              updateDonation(
                res.data.data.donation_no,
                "unpaid",
                response.error.metadata.payment_id
              );
            });

            rzp1.open();
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const mobileChangeHandler = (value, country) => {
    console.log(value, country);

    const countryCode = country.dialCode;
    console.log(countryCode, value);

    setDonorData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: null,
    }));
  };
  const changeHandler = (e) => {
    console.log(e);
    setDonorData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };
  const handleOtherAmountChange = (sevaName) => (event) => {
    const amount = event.target.value.trim();
    console.log(amount, "amount from otheramount");
    const isNumeric = new RegExp("^[0-9]+$").test(amount);
    if (amount.length > 0 && isNumeric) {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: amount }));

      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount((prevAmount) => {
          console.log(prevAmount, "prev amount for selected amount");
          // return prevAmount - prevAmountForSeva + Number(amount);
          return amount;
        });
        return { ...prev, [sevaName]: amount };
      });

      setSevasData(() => {
        return [{ name: sevaName, amount: amount }];
      });

      setErrorMsg("");
    } else if (amount === "") {
      setButtonClickedSevas((prev) => {
        Object.keys(prev).forEach((key) => {
          setAmountSevas((prevAmountSevas) => {
            const amountToSubtract = Number(prevAmountSevas[key] || 0);
            setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);
            const newAmountSevas = { ...prevAmountSevas };
            delete newAmountSevas[key];
            return newAmountSevas;
          });

          setSevasData((prevData) => {
            return prevData.filter((data) => data.name !== key);
          });
        });
        return {};
      });

      setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
      setAmountSevas((prev) => {
        const prevAmountForSeva = Number(prev[sevaName] || 0);
        setSelectedAmount((prevAmount) => prevAmount - prevAmountForSeva);
        const newAmountSevas = { ...prev };
        delete newAmountSevas[sevaName];
        return newAmountSevas;
      });

      setSevasData(() => {
        return [];
      });

      setErrorMsg("");
    } else {
      alert("Please Enter a Valid Amount");
    }
  };

  const handleButtonClick = (sevaName, buttonId, amount) => {
    const amountStr = String(amount);

    setButtonClickedSevas((prev) => {
      const prevButtonId = prev[sevaName];
      const isSameButtonClicked = prevButtonId === buttonId;

      if (isSameButtonClicked) {
        setAmountSevas((prevAmountSevas) => {
          const newAmountSevas = { ...prevAmountSevas };
          const amountToSubtract = Number(newAmountSevas[sevaName] || 0);

          setSelectedAmount((prevAmount) => prevAmount - amountToSubtract);

          delete newAmountSevas[sevaName];
          return newAmountSevas;
        });

        setSevasData((prevData) => {
          return prevData.filter((data) => data.name !== sevaName);
        });

        setIsCheckedSevas((prev) => ({ ...prev, [sevaName]: false }));
        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return {};
      } else {
        setAmountSevas((prevAmountSevas) => {
          const prevSevaName = Object.keys(prevAmountSevas).find(
            (key) => prevAmountSevas[key]
          );

          setSelectedAmount((prevAmount) => {
            const prevAmountForSeva = Number(
              prevAmountSevas[prevSevaName] || 0
            );
            const amountToAdd = Number(amountStr);
            return prevAmount - prevAmountForSeva + amountToAdd;
          });

          return { [sevaName]: amountStr };
        });

        setSevasData(() => {
          return [
            {
              name: sevaName,
              amount: amountStr,
            },
          ];
        });

        setIsCheckedSevas((prev) => {
          const newChecked = Object.keys(prev).reduce((acc, key) => {
            if (key !== sevaName) acc[key] = false;
            return acc;
          }, {});
          return { ...newChecked, [sevaName]: true };
        });

        setOtherAmounts((prev) => ({ ...prev, [sevaName]: "" }));
        setErrorMsg("");

        return { [sevaName]: buttonId };
      }
    });
  };

  return (
    <div>
      <div className="video-background" style={{ backgroundColor: "black" }}>
        {/* <video autoPlay muted loop className="background-video">
          <source src={videoSrc} type="video/mp4" />
        </video> */}
      </div>
      <section className="header-donate">
        <div className="container-fluid pl-md-5 pr-md-5">
          <div className="row">
            <div className="col-md-9 col-5 text-left ">
              <img src={headerlogo} className="header-logo" alt="header-logo" />
            </div>
            <div className="col-md-3 col-7  text-left ">
              <button
                className="btn btn-h-donate "
                onClick={playPause}
                style={{ borderRadius: "30px" }}
              >
                {isAudioPlaying ? (
                  <>
                    <marquee width="60%" direction="right" height="18px">
                      Hare Krishna Mantra
                    </marquee>
                    <img
                      src={audioOn}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Pause"
                    />
                  </>
                ) : (
                  <>
                    Hare Krishna Mantra
                    <img
                      src={audioOff}
                      style={{ height: "15%", width: "15%", marginLeft: "5%" }}
                      alt="Play"
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section">
        <div className="container-fluid px-0">
          <div className="row mx-0">
            <div className="col-md-12 px-0">
              <div className="banner-image">
                <img src={kmBanner} alt="Banner" className="banner-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "2%" }}>
        <h2 className=" seva-category text-center ">Kartik Month 2024</h2>
        <br></br>
        <h4 className="seva-category-description   ">
          Kartik month the most auspicious month and is a most dear month to sri
          sri Radha Madan Mohan . It is described in Hari Bhakti Vilasa
          16/39,40,
        </h4>
      </section>
      <Row>
        <Col sm={12} md={6}>
          <section>
            <div style={{ marginTop: "15%" }}>
              <h5 className="dec-h5 " style={{ marginLeft: "5%" }}>
                {" "}
                “na kartika samo maso na krtena samam yugam 
              </h5>
              <h5 className="dec-h5 " style={{ marginLeft: "5%" }}>
                {" "}
                na veda sadrsam na tirtha gangaya samam
              </h5>
              <h5 className="dec-h5 " style={{ marginLeft: "5%" }}>
                {" "}
                kartikah pravaro maso vaisnavanam priyah sada
              </h5>
              <h5 className="dec-h5 " style={{ marginLeft: "5%" }}>
                {" "}
                kartikam skalam yastu bhaktya sevate vaisnavah”
              </h5>
              <p className="desc-para-pitru-paksha mt-5 mb-3 ">
                There is no other month equal to Kartika and there is no yuga
                equal to Satya-yuga. There is no scripture equal to the Vedas.
                There is no place of pilgrimage equal to the Ganges. Kartika is
                the chief of all months and is very dear to the Vaishnavas
                (devotees of Krishna). Therefore, the Vaisnavas serve the
                Supreme Lord with great devotion in this month.{" "}
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
                {" "}
                Kartik Month Start Date:
                This year, Kartik Month falls from 17 October 2024 to 15 November 2024.
              </p>

              <p className="desc-para-pitru-paksha mt-2 mb-3">
              Why Kartik is Known as Damodar Maas?
              “Dama” means -“rope” and “udara ” means – “belly”
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
              It is not possible to describe the glories of Kartika month. In this month, devotees observe strict vows (Damodara Vrata) and worship Lord Damodara by offering a ghee lamp daily. It is said that by offering a lamp to Lord Hari in the month of Kartika, one gets unlimited prosperity, beauty and wealth. All the sins committed in thousands and millions of births perish, and one attains the eternal spiritual world where there is no suffering.
              In this month, every day, the temple is decorated with thousands of lamps. Light from hundreds of lamps before the garbha-griha spread transcendental radiance across the main temple hall, elevating the spirit of devotion. The grandeur with which the festivities are carried out captivates everyone’s realm of devotion.

              </p>

              {/* <p className="desc-para-pitru-paksha mt-2 mb-3">
              This month is also known as Damodar maas as little Krishna performed his sweetest pastime ‘Damodar lila’ in Kartik month on the day of Diwali. One beautiful morning, Mother Yashoda was churning milk, singing and remembering Krishna’s pastimes and little Krishna appeared before her. He was hungry and to increase her mother’s love, he wanted his mother Yashoda to stop churning and feed him her milk first. Mother Yashoda lovingly took Krishna on her lap and started feeding him her breast milk, she was relishing the moment and was looking at little Krishna’s beautiful face. Suddenly the milk that was kept on stove started boiling.
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
              To stop milk from boiling and spilling all over Mother Yashoda at once put Krishna aside and went to the stove at that moment Krishna became very angry and his face became red in rage. To show his anger to Mother Yashoda, he broke butter pot, took butter out of it and began to eat. In meantime, Mother Yashoda returned and she saw a broken pot. Since she couldn’t find Krishna around, she concluded that Krishna might have done this, she started looking for Krishna around.
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
              She found Krishna on the ceiling and feeding butter to monkeys. She took a stick in her hand to punish Krishna. She silently tried to catch hold of him but Krishna sensed her presence and he started running. Mother Yashoda chased him all around but little Krishna was running fast. Somehow she caught him. After being caught by her, little Krishna started crying. He smeared his lotus hand over his eyes filled with tears. He was in fear and was trying to cool down Mother Yashoda’s anger by showing his cute face but Mother Yashoda wanted to punish him, so she threw her stick and thought of binding him with ropes. It was impossible to bind his hands so she tried tying him up to grinding mortar. 
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
              It’s impossible to bind the supreme personality of the godhead and this is the reason she was facing difficulty in tying Krishna, on the other hand seeing her mother struggling, Krishna agreed to be bound by her. After binding him, Mother Yashoda engaged herself in household affairs. After that Krishna saw two big trees around him known as Arjuna tree. Krishna thinks that Mother Yashoda did wrong to him by binding him to such big grinding mortar even after he didn’t commit any mistake, he blamed Mother Yashoda for leaving him without feeding him sufficient milk and as a result of this, he broke the pot and distributed all butter in charity to the monkeys. According to him, he didn’t do anything wrong so to do something more mischievous than before he planned to uproot two big arjuna trees. With all of his strength, he pulled grinding mortar in the direction of the arjuna tree and passed it between the two trees.
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
              As a result of his persistent pulling of grinding mortar, the arjuna tree was uprooted, and two of Kuvera’s sons—named Nalapuvera and Manigriva—appeared from those trees. They were cursed by Naradmuni, therefore they turned into arjuna trees. By Krishna’s causeless mercy, they were again promoted to their life of demigods and they became great devotees of lord. After hearing the thunderbolt sound of a tree’s falling, Mother Yashoda, inhabitants of Gokul including Nand Maharaj came to the spot, they saw trees had fallen and they discovered no reason why they fell so they thought it must have been done by some demon.
              </p>
              <p className="desc-para-pitru-paksha mt-2 mb-3">
               Thus, Krishna through Damodar Lila, showed us how one can bind him with the ropes of love, affection and devotion.
               </p> */}
               <p className="desc-para-pitru-paksha mt-2 mb-3">
               Bring a smile to someone’s face by donating to Anna-Daan under the Food for Life program, and experience Krishna’s Divine shower of blessing in your life.               </p>

            </div>
          </section>
        </Col>
        <Col sm={12} md={6}>
          <section className=" " style={{ marginTop: "10%" }}>
            <div className="  d-flex justify-content-center align-items-center">
              <div className="desk-form-div " id="offer-seva">
                <div>
                  <h2 className="text-center seva">
                    <img src={leftdesign} alt="" />
                    Offer a Seva
                    <img src={rightdesign} alt="" />
                  </h2>
                </div>
                <Row>
                  <Col>
                    <Row style={{ marginTop: "1%" }}>
                      <Col md={12} sm={12}>
                        <div>
                          <h4
                            className="annadanam-tag-pitrupaksha"
                            style={{ marginTop: "2%" }}
                          >
                            ( Please select seva below )
                          </h4>
                        </div>

                        <h4
                          className="  annadanam-pitrupaksha"
                          style={{ marginTop: "2%" }}
                        >
                          {"   "} Annadanam Seva
                        </h4>
                        {/* firstSeva */}
                        <div className="seva-container-pitru-paksha">
                          <div className="seva-label2-pitru-paksha-container">
                            <Row>
                              <Col sm={12} md={6}>
                                <label className="seva-label2-pitru-paksha">
                                  Annadanam Seva
                                </label>
                              </Col>
                              <Col>
                                <input
                                  type="text"
                                  placeholder="Other Amount"
                                  value={otherAmounts["Annadanam Seva"] || ""}
                                  onChange={handleOtherAmountChange(
                                    "Annadanam Seva"
                                  )}
                                  style={{
                                    borderRadius: "20px",
                                    border: "none",
                                    padding: "15px",
                                    paddingLeft: "5%",
                                    outline: "none",
                                    marginLeft: "20%",
                                    width: "100%",
                                    maxWidth: "200px",
                                    height: "65%",
                                    boxSizing: "border-box",
                                  }}
                                  className="otherAmountSeva"
                                />
                              </Col>
                            </Row>
                          </div>
                          <label className="seva-description">
                            Sponsor Annadanam for 100/200/500 people
                          </label>

                          {
                            <div className="amountButtonDirection">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  className="checkbox-btn"
                                  style={{
                                    backgroundImage:
                                      buttonClickedSevas["Annadanam Seva"] ===
                                      "button1"
                                        ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                        : "none",
                                  }}
                                  onClick={() =>
                                    handleButtonClick(
                                      "Annadanam Seva",
                                      "button1",
                                      2501
                                    )
                                  }
                                >
                                  {" "}
                                  Rs.2,501
                                </button>
                                <p
                                  style={{ marginLeft: "15%" }}
                                  className="seva-amount-description"
                                >
                                  {" "}
                                  (for 100 visitors)
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  className="checkbox-btn"
                                  style={{
                                    backgroundImage:
                                      buttonClickedSevas["Annadanam Seva"] ===
                                      "button2"
                                        ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                        : "none",
                                  }}
                                  onClick={() =>
                                    handleButtonClick(
                                      "Annadanam Seva",
                                      "button2",
                                      5001
                                    )
                                  }
                                >
                                  {" "}
                                  Rs.5,001
                                </button>
                                <p
                                  style={{ marginLeft: "15%" }}
                                  className="seva-amount-description"
                                >
                                  {" "}
                                  (for 200 visitors)
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  className="checkbox-btn"
                                  style={{
                                    backgroundImage:
                                      buttonClickedSevas["Annadanam Seva"] ===
                                      "button3"
                                        ? "linear-gradient(180deg, #d49a2d, #fce484, #ecc759, #f5d96b)"
                                        : "none",
                                  }}
                                  onClick={() =>
                                    handleButtonClick(
                                      "Annadanam Seva",
                                      "button3",
                                      12501
                                    )
                                  }
                                >
                                  {" "}
                                  Rs.12,501
                                </button>
                                <p
                                  style={{ marginLeft: "15%" }}
                                  className="seva-amount-description"
                                >
                                  (for 500 visitors)
                                </p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                              
                              
                              </div>
                            </div>
                          }
                        </div>

                        <div
                          className="otherAmount-pitru-paksha"
                          style={{
                            flexDirection: "column",
                            boxSizing: "border-box",
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!anyCheckboxSelected && showError && (
                  <p className="error " style={{ color: "red" }}>
                    Please select at least one Seva and Amount
                  </p>
                )}
                <h4
                  className="seva-category text-center mt-5 "
                  style={{ marginLeft: "1%" }}
                >
                  Please Provide Details for Donation
                </h4>

               
                {errorMsg && showError && (
                  <div style={{ color: "red", marginLeft: "10%" }}>
                    {errorMsg}
                  </div>
                )}

                <div className="formwrapper text-center mt-1">
                  <div className="group">
                    <div className="input-div ">
                      <label className="input-margin-right-pitru">
                        Enter Name
                      </label>
                      <input
                        type="text"
                        className="input "
                        id="donorName"
                        name="donorName"
                        value={donorData.donorName}
                        ng-model="donorName"
                        placeholder="Enter Donar Name"
                        required
                        tabIndex="1"
                        maxLength="200"
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                    <div>
                      {errors.donorName && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.donorName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="group ">
                    <div className="input-div">
                      <label className="input-margin-right-pitru">
                        Enter Email Id
                      </label>
                      <input
                        type="text"
                        className="input"
                        id="emialID"
                        name="emailId"
                        value={donorData.emailId}
                        ng-model="emailId"
                        placeholder="Enter Email Id"
                        required
                        tabIndex="1"
                        maxLength="100"
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                    <div>
                      {errors.emailId && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.emailId}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="group ">
                    <div className="input-div">
                      <label className="mobile_number-pitru">
                        Enter Mobile Number
                      </label>
                      <PhoneInput
                        country={"in"}
                        value={donorData.mobileNumber}
                        onChange={mobileChangeHandler}
                        placeholder="Enter mobile number"
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        dropdownStyle={{ color: "black", textAlign: "left" }}
                        enableSearch={true}
                        inputStyle={{
                          width: windowSize.width < 767 ? "80%" : "100%",
                        }}
                      />
                    </div>

                    <div>
                      {errors.mobileNumber && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.mobileNumber}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="group ">
                    <div>
                      <label className="dob-pitru">Enter Date of Birth</label>

                      {/* <input
                    type="text"
                    className=" date-input-rath  p-2"
                    id="birthdatepicker"
                    name="dob"
                    placeholder="Enter mobile number"
                    value={donorData.dob}
                    onChange={(e) => changeHandler(e)}
                    required
                    tabIndex="1"
                  /> */}
                      <input
                        type="text"
                        className="input"
                        id="birthdatepicker"
                        name="dob"
                        placeholder="DD/MM/YYYY"
                        value={donorData.dob}
                        onChange={(e) => changeHandler(e)}
                        tabIndex="1"
                      />
                    </div>
                    <div>
                      {errors.dob && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.dob}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="group ">
                    <div>
                      <label className="input-margin-right-pitru">
                        Enter Address
                      </label>
                      <textarea
                        type="text"
                        className="textArea"
                        id="address"
                        name="address"
                        value={donorData.address}
                        ng-model="address"
                        placeholder="Enter Address"
                        required
                        tabIndex="1"
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                    <div>
                      {errors.address && (
                        <p className="error " style={{ color: "red" }}>
                          {errors.address}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                  <p className="pg-note desk text-center payment-message mt-5 tax-note">
                    By continuing, you are agreeing to
                    <a
                      href="https://iskconhyderabad.com/terms-and-conditions/"
                      target="_blank"
                      style={{ marginLeft: "1%" }}
                    >
                      Terms of Use and Privacy Policy
                    </a>
                  </p>
                  <div className="text-center">
                    {console.log(selectedAmount)}
                    {selectedAmount !== 0 ? (
                      <Button
                        className="btn btn-h-donate"
                        variant="none"
                        style={{ marginTop: "3%", borderRadius: "30px" }}
                        onClick={handleDonateNow}
                      >
                        Donate Rs-{selectedAmount}
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-h-donate pl-2"
                        style={{ borderRadius: "30px" }}
                        variant="none"
                        onClick={handleDonate}
                      >
                        Donate Now
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            <style jsx>{`
              .loading-spinner-container {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                z-index: 9999;
              }
            `}</style>
          </section>
        </Col>
      </Row>

      <section>
        <div className="mt-5">
          <h3
            className="gal-h5 mb-3 mt-5 text-center "
            style={{ color: "white" }}
          >
            Gallery
          </h3>
          <div
            style={{
              backgroundColor: "#6c2e5d",
              padding: "20px 20px",
              marginLeft: "10%",
              width: "80%",
              borderRadius: " 20px",
            }}
          >
            <div className="wrapper ">
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/01-2-scaled.jpg"
                  alt="Ekantha"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/04-1-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/DSC05369-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>

              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/02-1-scaled.jpg"
                  alt="Dhvaja"
                />
              </div>
              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/WhatsApp-Image-2024-08-05-at-2.35.35-PM.jpeg"
                  alt="Ekantha"
                />
              </div>

              <div>
                <img
                  src="http://iskconhyderabad.com/wp-content/uploads/09-1-scaled.jpg"
                  alt="Brahmotsava"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "10%" }}>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-8 my-auto text-center order-1 mt-4 order-sm-2">
              <img src={headerlogo} className="footer-logo" alt="footer-logo" />
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-3 mt-4  order-sm-1">
              <p className="mb-0 footer-para">Email: abidsiskcon@gmail.com </p>
              <p className="mb-0 footer-para">
                Copyright - 2024 All Right Reserved
              </p>
            </div>
            <div className="col-md-3 col-sm-8 my-auto text-center order-2 mt-4 order-sm-3">
              <h5 className="footer-h5">ISKCON ABIDS, HYDERABAD</h5>
              <p className="mb-0 footer-para">
                Old Kattal Mandi, Abids, Hyderabad, Telangana 500001
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Modal
          show={modalShow}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Processing Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="loading-spinner-container">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={true}
              />
            </div>

            <p style={{ marginTop: "25%", color: "blue" }}>
              Please Wait. Your payment is currently being processed.
              <br /> Do not refresh or close your browser.
            </p>
          </Modal.Body>
        </Modal>

        <Modal show={modalShowSuccess} onHide={handleCloseSuccess} centered>
          <Modal.Header closeButton>
            <Modal.Title>Payment Sucessful</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p style={{ marginTop: "5%", color: "green", fontSize: "18px" }}>
                Thanks For The Payment
              </p>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
};
export default KartikMahotsav;
